import { Box, Drawer } from '@mui/material';
import Topbar from '../common/Topbar';
import sizeConfigs from '../../configs/sizeConfigs';
import Sidebar from '../common/Sidebar';
import colorConfigs from '../../configs/colorConfigs';
import { Outlet } from 'react-router-dom';
import React from 'react';

function MainLayout() {

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Topbar handleDrawerToggle={handleDrawerToggle} />
            <Box component="nav"
                sx={{
                    width: { lg: sizeConfigs.sidebar.width },
                    flexShrink: { lg: 0 },
                    backgroundColor: colorConfigs.sidebar.bg
                }}
            >
                {/* Variant for large screen sizes */}
                <Drawer
                    variant='permanent'
                    sx={{
                        display: { xs: "none", lg: "block" }
                    }}
                    open
                >
                    <Sidebar />
                </Drawer>

                {/* Variant for small screen sizes */}
                <Drawer
                    variant='temporary'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", lg: "none" }
                    }}
                >
                    <Sidebar />
                </Drawer>

            </Box>

            <Box component="main" sx={{
                flexGrow: 1,
                p: 3,
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                minHeight: "100vh",
                backgroundColor: colorConfigs.mainBg,
                paddingTop: sizeConfigs.topbar.height,
            }}>
                <Outlet />
            </Box>
        </Box>
    );
}

export default MainLayout;