import { Box, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { Abonnement } from "../../models/Abonnement";
import { Order } from "./AbonnementTable";
import colorConfigs from "../../configs/colorConfigs";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


type Props = {
    orderBy: keyof Abonnement,
    order: Order,
    createSortHandler: (property: keyof Abonnement) => (event: React.MouseEvent<unknown>) => void,
    abosOnly?: boolean
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Abonnement;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: "aboName", numeric: false, disablePadding: false, label: 'Title' },
    { id: "aboType", numeric: false, disablePadding: false, label: 'Type' },
    { id: "publisher", numeric: false, disablePadding: false, label: 'Publisher' },
];


function ExtendedTableHead(props: Props) {
    const headingColor = colorConfigs.sidebar.activeBg;
    return (
        <TableHead>
            <TableRow
                sx={{
                    "& th": {
                        backgroundColor: headingColor,
                        color: "#fff",
                        " .MuiTableSortLabel-root:hover": {
                            color: "#fff"
                        },
                        " .MuiTableSortLabel-root.Mui-active": {
                            color: "#fff"
                        },
                        " .MuiTableSortLabel-icon": {
                            color: "#fff !important"
                        }
                    }
                }}
            >
                {headCells.map(cell => {
                    return (
                        <TableCell

                            key={cell.id}
                            align={cell.numeric ? 'right' : 'left'}
                            padding={cell.disablePadding ? 'none' : 'normal'}
                            sortDirection={props.orderBy === cell.id ? props.order : false}
                        >
                            <TableSortLabel
                                active={props.orderBy === cell.id}
                                direction={props.orderBy === cell.id ? props.order : 'asc'}
                                onClick={props.createSortHandler(cell.id)}
                                color={"blue"}
                            >
                                {cell.label}
                                {props.orderBy === cell.id ? (
                                    <span style={{
                                        border: 0,
                                        clip: 'rect(0 0 0 0)',
                                        height: 1,
                                        margin: -1,
                                        overflow: 'hidden',
                                        padding: 0,
                                        position: 'absolute',
                                        top: 20,
                                        width: 1,
                                    }}      >
                                        {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                })}
                <TableCell><Box sx={{display: "flex", flexDirection:"row", alignItems: "center"}}>Availability <Tooltip title="This indicates how the journal itself can be accessed from WIFO."><InfoOutlinedIcon sx={{fontSize: "1.3em", marginLeft: "3px"}}/></Tooltip></Box></TableCell>
                {
                    !props.abosOnly ? (
                        <TableCell align="right">Subscribed</TableCell>
                    ) : null
                }
                <TableCell align="right">More</TableCell>

            </TableRow>
        </TableHead>
    );
}

export default ExtendedTableHead;