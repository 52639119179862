import { ListItemButton, ListItemIcon } from "@mui/material";
import { RouteType } from "../../routes/config";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

type Props = {
    item: RouteType;
}

function SidebarItem(props: Props) {
    const { appState } = useSelector((state: RootState) => {
        return state.appState;
    });

    return (
        props.item.sidebarProps && props.item.path ? (
            <ListItemButton component={Link} to={props.item.path}
                sx={{
                    '&: hover': {
                        backgroundColor: colorConfigs.sidebar.hoverBg,
                        color: colorConfigs.sidebar.hoverColor
                    },
                    backgroundColor: appState === props.item.state ? colorConfigs.sidebar.activeBg : "unset",
                    color: appState === props.item.state ? colorConfigs.sidebar.activeColor : "unset",
                    paddingY: "12px",
                    paddingX: "24px"
                }}
            >
                <ListItemIcon sx={{
                    color: appState === props.item.state ? colorConfigs.sidebar.activeColor : "unset",
                }}>
                    {props.item.sidebarProps.icon && props.item.sidebarProps.icon}
                </ListItemIcon>
                {props.item.sidebarProps.displayText}
            </ListItemButton>
        ) : null
    );
}

export default SidebarItem;