import { IconButton, TableCell, TableRow } from "@mui/material";
import { Abonnement, Availability } from "../../models/Abonnement";
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from "react";
import TrafficLight from "./TrafficLight";
import ExpandedTableRowContent from "./ExpandedTableRowContent";
import colorConfigs from "../../configs/colorConfigs";

type Props = {
    abo: Abonnement,
    abosOnly?: boolean,
    availability: Availability,
    isSubscribed: boolean
}

function ExpandableTableRow(props: Props) {

    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow
                key={props.abo.aboId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {props.abo.aboName}
                </TableCell>
                <TableCell>{props.abo.aboType}
                </TableCell>
                <TableCell>{props.abo.publisher}
                </TableCell>
                <TableCell><TrafficLight availybility={props.availability} /></TableCell>
                {!props.abosOnly ?
                    (<TableCell align="right">{props.isSubscribed ? <CheckIcon /> : null}
                    </TableCell>) : null
                }
                <TableCell padding="checkbox" align="right">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            {
                isExpanded ? (
                    <TableRow>
                        <TableCell colSpan={props.abosOnly ? 5 : 6} sx={{ backgroundColor: colorConfigs.table.rowBg }}>
                            <ExpandedTableRowContent isSubscribed={props.isSubscribed} abo={props.abo} />
                        </TableCell>
                    </TableRow>
                ) : null
            }
        </>
    );
}

export default ExpandableTableRow;