import { Tooltip } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import { Availability } from '../../models/Abonnement';
import './TrafficLight.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type Props = {
    availybility: Availability
}

function TrafficLight(props: Props) {
    return (
        <>
            <div style={
                {
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                }
            }>
                <div className='light' style={{ backgroundColor: props.availybility === 'none' ? colorConfigs.trafficLight.red : "unset" }}></div>
                <div className='light' style={{ backgroundColor: props.availybility === 'library' ? colorConfigs.trafficLight.yellow : "unset" }}></div>
                <div className='light' style={{ backgroundColor: props.availybility === 'all' ? colorConfigs.trafficLight.green : "unset" }}></div>
                <Tooltip title={props.availybility === "none" ? "Articles are only available through library purchase" : props.availybility === "library" ? "Articles are available through library" : "Articles are available for everyone with a WIFO IP"}><InfoOutlinedIcon sx={{ fontSize: "1.3em", marginLeft: "3px", color: "grey" }} /></Tooltip>
            </div>
        </>
    );
}

export default TrafficLight;