import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Abonnement } from "../../models/Abonnement";

type abonnementListState = {
    abonnementListState: Array<Abonnement>
    userAbonnementListState: Array<Abonnement>
}

const initialState: abonnementListState = {
    // Corresponds to the full list of abonnements
    abonnementListState: [],
    // Corresponds to the list of abonnements the user is subscribed to
    userAbonnementListState: []
}

export const abonnementListSlice = createSlice({
    name: "abonnementListState",
    initialState,
    reducers: {
        subscribeAbonnement: (state, action: PayloadAction<number>) => {
            if (action.payload) {
                let abo = state.abonnementListState.find(abonnement => abonnement.aboId === action.payload)
                if (abo) {
                    if (!state.userAbonnementListState.find(abonnement => abonnement.aboId === action.payload)) {
                        state.userAbonnementListState.push(abo);
                    }
                }
            }
        },
        unsubscribeAbonnement: (state, action: PayloadAction<number>) => {
            if (action.payload) {
                state.userAbonnementListState = state.userAbonnementListState.filter(abonnement => abonnement.aboId !== action.payload);
            }
        },
        setAbonnementListState: (state, action: PayloadAction<Abonnement[]>) => {
            if (action.payload) {
                state.abonnementListState = action.payload;
            }
        },
    }
});



export default abonnementListSlice.reducer;