import { Box } from "@mui/material";
import AbonnementTable from "../../components/abonnementTable/AbonnementTable";
import { useEffect } from "react";
import { AboApiData, Abonnement } from "../../models/Abonnement";
import axios from "axios";
import APIConfig from "../../configs/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { abonnementListSlice } from "../../redux/features/abonnementListSlice";
import { RootState } from "../../redux/store";
import { useGetAPIAccessToken, useIsPrivileged } from "../../authentication/useHandleLogin";
import { useMsal } from "@azure/msal-react";


function DashboardPage() {

    const abonnementsSelector = useSelector((state: RootState) => state.abonnementListState.abonnementListState);
    const userAbonnementsSelector = useSelector((state: RootState) => state.abonnementListState.userAbonnementListState);
    const getAccessToken = useGetAPIAccessToken();
    const { instance } = useMsal();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get<AboApiData>(`${APIConfig.baseURL}abo/`);
            const transformedData: Abonnement[] = response.data.map((item) => ({
                aboId: item.id,
                aboName: item.name.trim().charAt(0).toUpperCase() + item.name.trim().slice(1),
                aboType: item.type,
                issn: item.issn,
                pictureUrl: item.pictureUrl,
                is_library_subscribed: item.library_is_subscribed,
                url: item.url,
                publisher: item.publisher,
                impact: undefined,  // Default value
                description: item.description,
                pattern: undefined,  // Default value
                availability: item.availability,
            }));

            dispatch(abonnementListSlice.actions.setAbonnementListState(transformedData));
        }

        fetchData();
    }, []);

    useEffect(() => {
        if(getAccessToken === null) {
            console.warn("Login attempt failed. No access token available.")
            return;
        }
        axios.get<AboApiData>(`${APIConfig.baseURL}user/${instance.getActiveAccount()?.idTokenClaims?.oid}/abos`, { headers: { Authorization: `Bearer ${getAccessToken}` } }).then((response) => {
            const ids: number[] = response.data.map((item) => item.id);
            ids.forEach(userAboId => {
                dispatch(abonnementListSlice.actions.subscribeAbonnement(userAboId));
            });
        }).catch((error) => {
            console.log("Fetching failed.")
        });
    }, [abonnementsSelector, getAccessToken]);

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Box width="80%" alignSelf={"center"} display="flex" flexDirection="column" marginY={"30px"}>
                    <AbonnementTable heading="My Subscriptions" data={userAbonnementsSelector} abosOnly />
                    <AbonnementTable heading="All Subscriptions" data={abonnementsSelector} />
                </Box>
            </div>
        </>
    );
}


export default DashboardPage;