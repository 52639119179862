import { colors } from '@mui/material'

const colorConfigs = {
    sidebar: {
        //bg: "#2b4f47",
        bg: "#b9d9d2",
        color: "#000",
        hoverBg: "#3f766b",
        activeBg: "#3f766b",
        activeColor: "#fff",
        hoverColor: "#fff"
    },
    topbar: {
        bg: '#b9d9d2',
        color: '#000'
    },
    mainBg: colors.grey["100"],
    trafficLight: {
        red: "#c3423f",
        yellow: "#f3d039",
        green: "#4b9847"
    },
    button: {
        outlined: {
            border: '#b9d9d2',
            color: '#2b4f47',
            backgroundHover: '#b9d9d266', // 40% Opacity added
        }
    },
    table: {
        rowBg: '#b9d9d233'
    }
}

export default colorConfigs;