import { RouteType } from "./config";
import DashboardPage from "../pages/dashboard/DashboardPage";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ChangelogPage from "../pages/changelog/ChangelogPage";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdminPageLayout from "../pages/admin/AdminPageLayout";
import AdminUpdatePage from "../pages/admin/AdminUpdatePage";
import RequestPage from "../pages/request/RequestPage";
import LoginPage from "../pages/login/LoginPage";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

const appRoutes: RouteType[] = [
    {
        path: "/",
        element: <LoginPage />,
        state: "login",
    },
    {
        path: "/dashboard",
        //index: true,
        element: <DashboardPage />,
        state: "dashboard",
        protected: true,
        sidebarProps: {
            displayText: "Dashboard",
            icon: <DashboardOutlinedIcon />
        },
    },
    {
        path: "/request",
        element: <RequestPage />,
        state: "request",
        protected: true,
        sidebarProps: {
            displayText: "Journal Request",
            icon: <AddCircleOutlineOutlinedIcon />
        },
    },
    {
        path: "/admin",
        element: <AdminPageLayout />,
        state: "admin",
        protected: true,
        adminProtected: true,
        sidebarProps: {
            displayText: "Administration",
            icon: <AdminPanelSettingsOutlinedIcon />,
            bottomPosition: true
        },
        child: [
            {
                path: "/admin/update",
                element: <AdminUpdatePage />,
                state: "admin.update",
                protected: true,
                adminProtected: true,
                sidebarProps: {
                    displayText: "Update Metadata",
                }
            },
        ]
    },
    {
        path: "/changelog",
        element: <ChangelogPage />,
        state: "changelog",
        protected: true,
        sidebarProps: {
            displayText: "Changelog",
            icon: <FormatListBulletedIcon />,
            bottomPosition: true
        },
    },
    // {
    //     path: "/logout",
    //     element: <LogoutPage />,
    //     state: "logout", 
    //     sidebarProps: {
    //         displayText: "Logout",
    //         icon: <LogoutOutlinedIcon />,
    //         bottomPosition: true
    //     },
    // },
];

export default appRoutes;