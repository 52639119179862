import { Button, Typography } from "@mui/material";
import assets from "../../assets";
import { Navigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { useHandleLogin } from "../../authentication/useHandleLogin";

function LoginPage() {
    const isAuthenticated = useIsAuthenticated();
    const handleLogin = useHandleLogin();

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                gap: 30
            }}>
                <Typography variant="h5">
                    You must be logged in to access this tool. Please login with your office 365 account.
                </Typography>

                {
                    !isAuthenticated ? <Button variant="outlined" onClick={handleLogin}>
                        <img src={assets.images.microsoftIcon} /> Log in with Microsoft
                    </Button> : <Navigate to="/dashboard" replace />
                }
            </div>
        </>
    );
}

export default LoginPage;