import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import colorConfigs from "../../configs/colorConfigs";
import { ReactNode } from "react";

const CustomPrimaryButton = styled(Button)({
    borderColor: colorConfigs.button.outlined.border,
    color: colorConfigs.button.outlined.color,
    '&:hover': {
        backgroundColor: colorConfigs.button.outlined.backgroundHover,
        borderColor: colorConfigs.button.outlined.color
    }
});

type Props = {
    children: ReactNode,
    onClick?: () => void,
    style?: React.CSSProperties, 
    maxWidth?: string
}

export function CstPrimaryButton(props: Props) {
    return (
        <CustomPrimaryButton style={props.style} onClick={props.onClick} variant="outlined" sx={{ maxWidth: props.maxWidth ? props.maxWidth : "150px" }}>{props.children}</CustomPrimaryButton>
    );
}