
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import axios from 'axios';
import APIConfig from '../../configs/apiConfig';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMsal } from '@azure/msal-react';

interface IFormInputs {
    aboName: string,
    publisher: string,
    link: string,
    autoSubscribe: boolean,
    username: string
}

const schema = yup.object().shape({
    aboName: yup.string().min(4).required(),
    publisher: yup.string(),
    link: yup.string().url(),
    autoSubscribe: yup.bool()
});

function RequestPage() {

    const { register, handleSubmit, control, watch, formState: { errors }, reset } = useForm<IFormInputs>({ resolver: yupResolver(schema) });
    const { instance } = useMsal(); 
    const formSubmitHandler: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
        console.log('Handle form submit later here ... ');
        console.log('form data is: ' + JSON.stringify(data));
        data.autoSubscribe = data.autoSubscribe ? data.autoSubscribe : false;
        const username = instance.getActiveAccount()?.username;
        if (username) {
            data.username = username;
        } else {
            toast("Failed to get username. Please refresh the page.", { type: "error" });
        }
        // Send post request to backend here
        const send = await axios.post(`${APIConfig.baseURL}abo/requestform`, data, { headers: { 'Content-Type': 'application/json' } })
        if (send.status === 201) {
            toast("Successfully sent form to the library team. You will receive an e-mail once your abo is available.", { type: "success" });
            reset();
        } else {
            toast("Failed to send form to the library team. Please try again later.", { type: "error" });
        }
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <Typography variant='h4' marginY="50px" style={
                {
                    borderBottom: "5px solid",
                    borderColor: colorConfigs.sidebar.bg,
                    alignSelf: "center",
                }
            }>Request a new Publication</Typography>
            <Typography style={
                {
                    width: "80%",
                }
            }>This form can be used to request a journal or different kind of print media, that should be made available to receive alerts from. Before sending a request please make sure that the publication is indeed not yet available. Please also make sure, that the publications has e-mail alerts that can be subscribed to. We only support mail alerts currently. <br /> <br /> After submiting the request, the library team will check if it is possible to add the requested ressource and inform you about it. Please be aware, that depending on the journal there is some background work involved, so while normally requests get fulfilled on the same day, it may take up to a week until your subscription is available and ready to use. If you tick the button to get automatically subscribed, you will receive a confirmation once your subscription is actively running.</Typography>
            <form onSubmit={handleSubmit(formSubmitHandler)} style={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
                gap: "50px",
                marginTop: "50px"
            }}>
                <Controller name="aboName" control={control} defaultValue='' render={({ field }) => (
                    <TextField {...field} label="Name of Publication" placeholder="e.g. 'The Economist'" variant='outlined' error={!!errors.aboName} helperText={errors.aboName ? errors.aboName.message : ''}></TextField>
                )} />
                {/* <input defaultValue="" {...register('aboName')} />
            {errors.aboName && errors.aboName.message && <span>{errors.aboName.message}</span>} */}
                <Controller name="publisher" control={control} defaultValue='' render={({ field }) => (
                    <TextField {...field} label="Name of Publisher" placeholder="e.g. 'The Economist Newspaper Limited'" variant='outlined' error={!!errors.publisher} helperText={errors.publisher ? errors.publisher.message : ''}></TextField>
                )} />
                <Controller name="link" control={control} defaultValue='' render={({ field }) => (
                    <TextField type='text' {...field} label="Link to Publication" placeholder="e.g. 'https://www.economist.com/'" variant='outlined' error={!!errors.link} helperText={errors.link ? errors.link.message : ''}></TextField>
                )} />
                <Controller name="autoSubscribe" control={control} render={({ field }) => (
                    <FormControlLabel sx={{ flexDirection: "row-reverse" }} control={<Checkbox {...field} aria-label='Checkbox'></Checkbox>} label="After the journal has been added by the library team, I want to be subscribed to the alerts automatically." />
                )} />
                
                <Button type='submit' variant='outlined'>Submit</Button>
            </form>
            <ToastContainer style={{position: "absolute", bottom: "20px", right: "20px"}}/>
        </div>
    );
}

export default RequestPage;