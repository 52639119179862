import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { redirect, useNavigate } from "react-router-dom";
import { loginRequest } from "./authConfig";
import { AuthenticationResult } from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

export const useHandleLogin = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const navigate = useNavigate();

  return () => {
    if (isAuthenticated) {
      const logoutRequest = {
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest).then(() => {
        instance.setActiveAccount(null);
        navigate("/", { replace: true });
      });

    }
    else {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      }).then((res: AuthenticationResult | void) => {
        if (res) {
          instance.setActiveAccount(res.account);
          navigate("/dashboard", { replace: true })
        }
      });
    }
  };
};

export const useGetAPIAccessToken = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (!instance.getActiveAccount()) {
        setToken(null);
      } else {
        try {
          const res = await instance.acquireTokenSilent(loginRequest);
          setToken(res.accessToken);
        } catch (e) {
          try {
            const res = await instance.acquireTokenPopup(loginRequest);
            setToken(res.accessToken);
          } catch (e) {
            console.log(e);
          }
        }
      }
    };

    fetchToken();
  }, [instance, isAuthenticated]);

  return token;
}

export const useIsPrivileged = () => {
  const [isPrivileged, setIsPrivileged] = useState(false);
  const token = useGetAPIAccessToken();

  useEffect(() => {
    if (token) {
      let decodedToken:any = jwt_decode(token);
      if (decodedToken && 'roles' in decodedToken && Array.isArray(decodedToken.roles) && decodedToken.roles.includes("Privileged")) {
        setIsPrivileged(true);
      } else {
        setIsPrivileged(false);
      }
    } else {
      setIsPrivileged(false);
    }
  }, [token]);

  return isPrivileged;
}