import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import colorConfigs from '../../configs/colorConfigs';
import APIConfig from '../../configs/apiConfig';
import { useGetAPIAccessToken } from '../../authentication/useHandleLogin';


type Props = {
    title: string,
    patterns: Array<{ name: string, id: number }>,
    aboId: number
}

type PatternType = {
    name: string,
    id: number
}

export default function CustomizedInputBase(props: Props) {

    const [patterns, setPatterns] = useState<Array<{ value: PatternType, original: PatternType }>>(props.patterns.map(pattern => ({ value: pattern, original: pattern })));
    const [newPattern, setNewPattern] = useState("");
    const getAccessToken = useGetAPIAccessToken();

    useEffect(() => {
        setPatterns(props.patterns.map(pattern => ({ value: pattern, original: pattern })));
    }, [props.patterns]);

    const handleDelete = async (pid: number) => {
        const url = `${APIConfig.baseURL}abo/${props.aboId}/pattern/${pid}`; // Construct the API endpoint
        // Call API endpoint to delete a pattern
        await fetch(url, { method: 'DELETE', headers: { 'Authorization': `Bearer ${getAccessToken}` } });
        // Assuming that the API endpoint deletes the pattern, remove it from state
        setPatterns(patterns.filter(pattern => pattern.value.id !== pid));
    }

    const handleEdit = async (pid: number, newName: string) => {
        // Call API endpoint to update the pattern
        const url = `${APIConfig.baseURL}abo/${props.aboId}/pattern/${pid}`; // Construct the API endpoint
        await fetch(url, {
            method: 'PUT',
            body: JSON.stringify({ name: newName }),
            headers: { 'Authorization': `Bearer ${getAccessToken}`, 'Content-Type': 'application/json' }
        });
        // Assuming that the API endpoint updates the pattern, update it in state
        setPatterns(patterns.map(pattern => pattern.value.id === pid ? { ...pattern, value: { ...pattern.value, name: newName }, original: { ...pattern.original, name: newName } } : pattern));
    }

    const handleAdd = async () => {
        // Call API endpoint to create a pattern
        const response = await fetch(`${APIConfig.baseURL}abo/${props.aboId}/pattern`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${getAccessToken}`,
            },
            body: JSON.stringify({ name: newPattern }),
        });
        const newPatternResponse = await response.json();
        setPatterns([...patterns, { value: newPatternResponse, original: newPatternResponse }]);
        setNewPattern("");
    }

    return (
        <>
            <Typography variant="h5">
                {props.title}
            </Typography>
            <Divider sx={{ mb: 2 }} orientation="horizontal" />
            {patterns.map((pattern, index) => {
                return <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 250, my: 1.5 }}
                    key={index}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Add pattern ..."
                        inputProps={{ 'aria-label': 'add pattern' }}
                        value={pattern.value.name}
                        onChange={(e) => setPatterns(patterns.map(pat => pat.value.id === pattern.value.id ? { ...pat, value: { ...pat.value, name: e.target.value } } : pat))}
                    />
                    <IconButton type="button" sx={{ p: '10px', color: colorConfigs.trafficLight.green }} aria-label="add" disabled={pattern.value.name === pattern.original.name} onClick={() => handleEdit(pattern.value.id, pattern.value.name)}>
                        <CheckIcon />
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton sx={{ p: '10px', color: colorConfigs.trafficLight.red }} aria-label="delete" onClick={() => handleDelete(pattern.value.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Paper>
            })}
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 250, my: 1.5, mt: 3}}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Add pattern ..."
                    inputProps={{ 'aria-label': 'add pattern' }}
                    value={newPattern}
                    onChange={(e) => setNewPattern(e.target.value)}
                />
                <IconButton type="button" sx={{ p: '10px', color: colorConfigs.trafficLight.green }} aria-label="add" disabled={newPattern.length < 3} onClick={handleAdd}>
                    <CheckIcon />
                </IconButton>
            </Paper>
        </>
    );
}
