import { configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import abonnementListSlice from "./features/abonnementListSlice";

export const store = configureStore({
    reducer: {
        appState: appStateSlice,
        abonnementListState: abonnementListSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>
