import { Route } from "react-router-dom";
import { RouteType } from "./config";
import { ReactNode, useEffect } from 'react';
import PageWrapper from "../components/layout/PageWrapper";
import appRoutes from "./appRoutes";
import { useIsPrivileged } from "../authentication/useHandleLogin";
import React from "react";


const generateRoute = function (routes: RouteType[]): ReactNode {

    return routes.map((route, index) => (
        route.index ? (
            <Route
                index
                path={route.path}
                element={
                    <PageWrapper state={route.state} protected={route.protected}>
                        {route.element}
                    </PageWrapper>
                }
                key={index}
            />
        ) : (
            <Route
                path={route.path}
                element={
                    <PageWrapper state={route.child ? undefined : route.state} protected={route.protected}>
                        {route.element}
                    </PageWrapper>
                }
                key={index}
            >
                {route.child && (
                    generateRoute(route.child)
                )}
            </Route>
        )
    ));
}

export const routes: ReactNode = generateRoute(appRoutes);
