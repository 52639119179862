import { useState } from "react";
import { RouteType } from "../../routes/config";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SidebarItem from "./SidebarItem";

type Props = {
    item: RouteType
}

function SidebarItemCollapse(props: Props) {
    const [open, setOpen] = useState(false);
    return (
        props.item.sidebarProps ? (
            <>
                <ListItemButton
                    onClick={() => setOpen(!open)}
                    sx={{
                        '&:hover': {
                            backgroundColor: colorConfigs.sidebar.hoverBg
                        },
                        paddingY: "12px",
                        paddingX: "24px",
                    }}
                >
                    <ListItemIcon sx={{
                        color: colorConfigs.sidebar.color
                    }}>
                        {props.item.sidebarProps.icon && props.item.sidebarProps.icon}
                    </ListItemIcon>
                    <ListItemText disableTypography primary={
                        <Typography>{props.item.sidebarProps.displayText}</Typography>
                    }></ListItemText>
                    {
                        open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                </ListItemButton>
                <Collapse in={open} timeout="auto" >
                    <List>
                        {props.item.child && props.item.child.map((route, index) => {
                            return route.sidebarProps ?
                                (
                                    route.child ?
                                        (<SidebarItemCollapse item={route} key={index} />) :
                                        (<SidebarItem item={route} key={index} />)
                                ) :
                                null
                        })}
                    </List>
                </Collapse>

            </>
        ) : null
    );
}

export default SidebarItemCollapse;