import { CircularProgress, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import ExtendedTableHead from "./ExtendedTableHead";
import ExpandableTableRow from "./ExpandableTableRow";
import React, { ReactNode, useEffect } from "react";
import { Abonnement } from "../../models/Abonnement";
import SearchIcon from "@mui/icons-material/Search";
import sizeConfigs from "../../configs/sizeConfigs";
import colorConfigs from "../../configs/colorConfigs";
import { CstPrimaryButton } from "../common/CustomButton";
import './TrafficLight.css';
import CheckmarkFilterMenu from "./CheckmarkFilterMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
    heading: string
    data: Abonnement[]
    abosOnly?: boolean
}

export type Order = 'asc' | 'desc';


function AbonnementTable(props: Props) {

    const userAbonnementsSelector = useSelector((state: RootState) => state.abonnementListState.userAbonnementListState);

    function isSubscribed(abo: Abonnement) {
        return userAbonnementsSelector.some((userAbo) => userAbo.aboId === abo.aboId);
    }

    useEffect(() => {
        setRows(props.data);
        setAvailabilityFilteredDataset(props.data);
    }, [props.data]);

    // Start the timeout when the component is mounted
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000);

        return () => clearTimeout(timer); // This clears the timer when the component is unmounted
    }, []);


    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [searched, setSearched] = React.useState<string>("");
    const [row, setRows] = React.useState(props.data);
    const [availabilityFilteredDataset, setAvailabilityFilteredDataset] = React.useState(props.data);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Abonnement>('aboName');
    const [openFilter, setOpenFilter] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(true);



    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Abonnement) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof Abonnement) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    function handleChangePage(event: any, newpage: React.SetStateAction<number>) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event: any) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    const requestSearch = (event: any) => {
        const filteredRows = props.data.filter((row) => {
            setSearched(event.target.value);
            // Set page to zero so we don't get index out of bounds when searching from high page numbers
            setpg(0);
            return row.aboName.toLowerCase().includes(event.target.value.toLowerCase());
        });
        setRows(filteredRows);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }


    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: T[], comparator: (a: any, b: any) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function onFilterChange(filteroptions: Filter) {
        if (filteroptions.type == "availability") {
            const filterOut = filteroptions.filteroptions.filter(option => {
                return !option.show;
            }).map(option => option.option);
            let filteredRows: Abonnement[] = props.data;
            filterOut.forEach(filter => {
                filteredRows = filteredRows.filter((row) => {
                    if (filter == "Available") {
                        return row.availability != "all";
                    }
                    if (filter == "Available Through Library") {
                        return row.availability != "library";
                    }
                    if (filter == "Not Available") {
                        return row.availability != "none";
                    }
                });
            });
            setAvailabilityFilteredDataset(filteredRows);
        }

    }


    return (
        <>
            <Typography marginY={"50px"} variant="h4" style={
                {
                    borderBottom: "5px solid",
                    borderColor: colorConfigs.sidebar.bg,
                    alignSelf: "center"
                }
            } textAlign="center">{props.heading}</Typography>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: 20
            }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search Title"
                    value={searched}
                    onChange={requestSearch}
                    sx={{
                        width: `calc(81vw - ${sizeConfigs.sidebar.width})`,
                        maxWidth: "500px",
                        marginY: "10px",
                        '& label.Mui-focused': {
                            color: colorConfigs.sidebar.activeBg,
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: colorConfigs.sidebar.activeBg,
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: colorConfigs.sidebar.activeBg,
                            },
                            '&:hover fieldset': {
                                borderColor: colorConfigs.sidebar.activeBg,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: colorConfigs.sidebar.bg,
                            },
                        }
                    }}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <div>
                    <CstPrimaryButton onClick={() => setOpenFilter(!openFilter)}>More Filters</CstPrimaryButton>
                </div>
            </div>
            {openFilter ? <CheckmarkFilterMenu filteroptions={availabilityFilter} onFilterChange={onFilterChange} /> : null}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}
                    aria-label="simple table">
                    <ExtendedTableHead order={order} orderBy={orderBy} createSortHandler={createSortHandler} abosOnly={props.abosOnly} />
                    <TableBody>
                        {row.length != 0 ? stableSort(row.filter(value => availabilityFilteredDataset.includes(value)), getComparator(order, orderBy)).slice(pg * rpg, pg * rpg + rpg).map((row) => (
                            <ExpandableTableRow key={row.aboId} abo={row} isSubscribed={isSubscribed(row)} abosOnly={props.abosOnly} availability={row.availability} />
                        )) : <TableRow>
                            <TableCell colSpan={props.abosOnly ? 5 : 6} sx={{ backgroundColor: colorConfigs.table.rowBg, textAlign: "center" }}>
                                {loading ? <CircularProgress /> : <span>Nothing to see here.</span>}
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={(row.filter(value => availabilityFilteredDataset.includes(value))).length}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default AbonnementTable;

export type Filter = {
    type: string,
    label: string,
    filteroptions: { option: string, show: boolean, menuIcon?: ReactNode, }[],
}

const availabilityFilter: Filter = {
    type: "availability",
    label: "Availability",
    filteroptions: [
        {
            option: "Available",
            show: true,
            menuIcon: <div className="light" style={{ backgroundColor: colorConfigs.trafficLight.green, marginRight: "10px" }}></div>
        },
        {
            option: "Available Through Library",
            show: true,
            menuIcon: <div className="light" style={{ backgroundColor: colorConfigs.trafficLight.yellow, marginRight: "10px" }}></div>
        },
        {
            option: "Not Available",
            show: true,
            menuIcon: <div className="light" style={{ backgroundColor: colorConfigs.trafficLight.red, marginRight: "10px" }}></div>
        }
    ],

}