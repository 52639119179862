import { Avatar, Drawer, List, Stack, Toolbar, Typography } from '@mui/material';
import sizeConfigs from '../../configs/sizeConfigs';
import assets from '../../assets';
import colorConfigs from '../../configs/colorConfigs';
import appRoutes from '../../routes/appRoutes';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import { useIsPrivileged } from '../../authentication/useHandleLogin';
import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';


function Sidebar() {
    const isPrivileged = useIsPrivileged();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        console.log("Privilege changed: " + isPrivileged);
    }, [isPrivileged])

    useEffect(() => {
        console.log("IsAuthenticated changed: " + isAuthenticated);
    }, [isAuthenticated])

    return (
        <Drawer
            variant='permanent'

            sx={{
                width: sizeConfigs.sidebar.width,
                height: "100%",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: "border-box",
                    borderRight: "0px",
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                },
            }}
        >
            <List disablePadding>
                <Toolbar sx={{
                    marginY: "20px",
                }}>
                    <Stack
                        sx={{
                            width: "100%"
                        }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Avatar variant='square' src={assets.images.logo} sx={{
                            height: "60px",
                            width: "60px"
                        }} />
                        <Typography><small>Bib-Umlauftool v0.1</small></Typography>
                    </Stack>
                </Toolbar>
                {appRoutes.map((route, index) => {
                    return route.sidebarProps && !route.sidebarProps.bottomPosition ? (
                        route.adminProtected && isPrivileged && isAuthenticated ? (
                            route.child ?
                                (<SidebarItemCollapse item={route} key={index} />) :
                                (<SidebarItem item={route} key={index} />)

                        ) : !route.adminProtected ? (
                            route.child ?
                                (<SidebarItemCollapse item={route} key={index} />) :
                                (<SidebarItem item={route} key={index} />)

                        ) : null
                    ) : null
                })}
            </List>
            <List disablePadding>
                {appRoutes.map((route, index) => {
                    return route.sidebarProps && route.sidebarProps.bottomPosition ? (
                        route.adminProtected && isPrivileged && isAuthenticated ? (
                            route.child ?
                                (<SidebarItemCollapse item={route} key={index} />) :
                                (<SidebarItem item={route} key={index} />)

                        ) : !route.adminProtected ? (
                            route.child ?
                                (<SidebarItemCollapse item={route} key={index} />) :
                                (<SidebarItem item={route} key={index} />)

                        ) : null
                    ) : null
                })}
            </List>
        </Drawer>
    );
}



export default Sidebar;