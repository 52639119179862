import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import sizeConfigs from '../../configs/sizeConfigs';
import colorConfigs from '../../configs/colorConfigs';
import MenuIcon from "@mui/icons-material/Menu";
import { CstPrimaryButton } from './CustomButton';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import { useGetAPIAccessToken, useIsPrivileged, useHandleLogin } from '../../authentication/useHandleLogin';
import { useEffect, useState } from 'react';

type Props = {
    handleDrawerToggle: () => void;
}

function Topbar(props: Props) {
    const isAuthenticated = useIsAuthenticated();
    const handleLogin = useHandleLogin();
    const {accounts} = useMsal();
    const [claim, setClaim] = useState<any>();

    return (
        <AppBar position='fixed' sx={{
            width: { lg: `calc(100% - ${sizeConfigs.sidebar.width})` },
            marginLeft: { lg: sizeConfigs.sidebar.width },
            boxShadow: "unset",
            backgroundColor: colorConfigs.topbar.bg,
            color: colorConfigs.topbar.color,
            height: sizeConfigs.topbar.height,
            justifyContent: "center"
        }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={props.handleDrawerToggle}
                    sx={{
                        marginRight: 2,
                        display: { lg: "none" }
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h5">
                    {!isAuthenticated ? "Management von Journal- und Zeitschriftenalerts" : `Logged in as: ${accounts[0].name}`}
                </Typography>
                {isAuthenticated ? <CstPrimaryButton onClick={handleLogin} style={{ marginLeft: "auto", borderColor: colorConfigs.button.outlined.color }}><LogoutOutlinedIcon sx={{ marginRight: "10px" }} /> Logout</CstPrimaryButton>
                    : <CstPrimaryButton onClick={handleLogin} style={{ marginLeft: "auto", borderColor: colorConfigs.button.outlined.color }}><LoginOutlinedIcon sx={{ marginRight: "10px" }} /> Login</CstPrimaryButton>
                }

            </Toolbar>
        </AppBar>
    );
}

export default Topbar;