import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppState } from '../../redux/features/appStateSlice';
import { Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import APIConfig from '../../configs/apiConfig';
import { useGetAPIAccessToken } from '../../authentication/useHandleLogin';
import { getAccordionActionsUtilityClass } from '@mui/material';

type Props = {
    state?: string,
    children: ReactNode,
    protected?: boolean
}

function PageWrapper(props: Props) {
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const getAcessToken = useGetAPIAccessToken();

    useEffect(() => {
        if (props.state) {
            dispatch(setAppState(props.state));
        }
    }, [dispatch, props]);

    // Handle login state
    useEffect(() => {
        if (isAuthenticated) {
            // Fetch user info from database or create new user
            axios.get(`${APIConfig.baseURL}user/${instance.getActiveAccount()?.username}`).then((response) => {
                if (response.status === 200) {
                    return;
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    // Create User Object
                    const user = { oid: instance.getActiveAccount()?.idTokenClaims?.oid, firstname: instance.getActiveAccount()?.name?.split(" ")[0], lastname: instance.getActiveAccount()?.name?.split(" ")[1], email: instance.getActiveAccount()?.username }
                    axios.post(`${APIConfig.baseURL}user/`, user, { headers: { Authorization: `Bearer ${getAcessToken}` } }).then((response) => {
                        if (response.status === 201) {
                            return;
                        } else {
                            console.error("Failed to create user");
                        }
                    })
                }
            })
        }
    }, [isAuthenticated, getAcessToken])

    if (props.protected && isAuthenticated) {
        //return <Navigate to="/" replace />
        return <AuthenticatedTemplate>
            {props.children}
        </AuthenticatedTemplate>
    } else if (props.protected && !isAuthenticated) {
        return <Navigate to="/" replace />
    }
    else return <>{props.children}</>

    // return (
    //     <>{props.children}</>
    // );
}

export default PageWrapper;
