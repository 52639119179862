import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import { Filter } from "./AbonnementTable";

type Props = {
    onFilterChange: (filteroptions: Filter) => void,
    filteroptions: Filter
}

function CheckmarkFilterMenu({ onFilterChange, filteroptions }: Props) {

    const [filter, setFilter] = React.useState(filteroptions.filteroptions.map(option => option.option));

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
            
        );


        // Fix up filteroptions to return it
        let filterupdate = filteroptions;
        filterupdate.filteroptions = filterupdate.filteroptions.map(option => {
            if ((typeof value === 'string' ? value.split(',') : value).includes(option.option)) {
                option.show = true;
            } else {
                option.show = false;
            }
            return option;
        });
        onFilterChange(filterupdate);
    };

    return (
        <div>
            <FormControl sx={{ minWidth: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">{filteroptions.label}</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filter}
                    onChange={handleChange}
                    input={<OutlinedInput label={filteroptions.label} />}
                    renderValue={(selected) => selected.join(', ')}
                >

                    {filteroptions.filteroptions.map(option => {
                        return <MenuItem key={option.option} selected={option.show} value={option.option}>
                            <Checkbox checked={filter.includes(option.option)} />
                            {option.menuIcon && option.menuIcon}
                            <ListItemText primary={option.option}></ListItemText>
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export default CheckmarkFilterMenu;