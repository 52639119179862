import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Material UI Font Import
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Material UI
import { CssBaseline } from '@mui/material';

//Redux
import { Provider } from 'react-redux';
import { store } from './redux/store';

// Authentication
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser/dist/app/PublicClientApplication';
import { msalConfig } from './authentication/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
