import { Box, Select, Theme, ToggleButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Abonnement } from "../../models/Abonnement";
import assets from "../../assets";
import { CstPrimaryButton } from "../common/CustomButton";
import { useEffect, useState } from "react";
import APIConfig from "../../configs/apiConfig";
import { useDispatch } from "react-redux";
import { abonnementListSlice } from "../../redux/features/abonnementListSlice";
import { useGetAPIAccessToken, useIsPrivileged } from "../../authentication/useHandleLogin";
import { useMsal } from "@azure/msal-react";
import { resolve } from "path";
import CustomizedInputBase from "../common/CustomizedInputBase";
import { set } from "react-hook-form";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    abo: Abonnement,
    isSubscribed: boolean
}


function ExpandedTableRowContent(props: Props) {
    const getAccessToken = useGetAPIAccessToken();
    const { accounts } = useMsal();
    const isPrivileged = useIsPrivileged();
    const dispatch = useDispatch();
    const [imageUrl, setImageUrl] = useState("");
    const [patterns, setPatterns] = useState<Array<{ id: number, name: string }>>([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const theme: Theme = useTheme();
    const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
    const [isLibrarySub, setIsLibrarySub] = useState(props.abo.is_library_subscribed);


    


    async function handleSubscriptionClick() {
        const url = `${APIConfig.baseURL}user/${accounts[0].idTokenClaims?.oid}/abos/${props.abo.aboId}`; // Construct the API endpoint

        if (props.isSubscribed) {
            // Unsubscribe
            const response = await fetch(url, { method: 'DELETE', headers: { 'Authorization': `Bearer ${getAccessToken}` } });

            if (response.ok) {
                // Update the subscription status in global state
                updateSubscriptionStatus(false);
            } else {
                console.error('Failed to unsubscribe');
            }
        } else {
            // Subscribe
            const response = await fetch(url, { method: 'POST', headers: { 'Authorization': `Bearer ${getAccessToken}` } });

            if (response.ok) {
                // Update the subscription status in global state
                updateSubscriptionStatus(true);
            } else {
                console.error('Failed to subscribe');
            }
        }
    }

    async function handleShowPrivilegedContentClick() {
        if (isPrivileged && !isExpanded) {
            const url = `${APIConfig.baseURL}abo/${props.abo.aboId}`; // Construct the API endpoint
            const response = await fetch(url, { method: 'GET', headers: { 'Authorization': `Bearer ${getAccessToken}` } });
            if (response.ok) {
                // Update the patterns in state
                const data: Array<{ id: number, name: string }> = (await response.json()).patterns;
                setPatterns(data);
            }
        }
        setIsExpanded(!isExpanded);
    }

    function updateSubscriptionStatus(to: boolean) {
        if (to) {
            dispatch(abonnementListSlice.actions.subscribeAbonnement(props.abo.aboId));
        } else {
            dispatch(abonnementListSlice.actions.unsubscribeAbonnement(props.abo.aboId));
        }
    }

    function handleSetIsLibrarySub() {
        const url = `${APIConfig.baseURL}abo/${props.abo.aboId}/librarysub`; // Construct the API endpoint
        fetch(url, {
            method: 'PATCH',
            headers: { 'Authorization': `Bearer ${getAccessToken}`, 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.ok) {
                setIsLibrarySub(!isLibrarySub);
            }
        });
    }

    let imageObjectUrl: string = "";
    useEffect(() => {
        fetch(`${APIConfig.baseURL}abo/picture/${props.abo.issn}`)
            .then((res) => {
                if (res.ok) {
                    return res.blob();
                }
                else return Promise.reject("Failed to fetch image");
            })
            .then((imageBlob) => {
                imageObjectUrl = URL.createObjectURL(imageBlob);
                setImageUrl(imageObjectUrl);
            })
            .catch((error) => { });

        // Cleanup function
        return () => {
            if (imageObjectUrl) {
                URL.revokeObjectURL(imageObjectUrl);
            }
        };
    }, []);


    return (
        <>
            <div style={
                {
                    display: "flex",
                    gap: "20px",
                    flexDirection: matchesLG ? "column" : "row",
                }
            }>
                <Box
                    component="img"
                    sx={{
                        height: 200,
                        minWidth: "250px",
                        maxWidth: "300px",
                        marginRight: "20px",
                    }}
                    alt="Publication Picture"
                    src={imageUrl ? imageUrl : assets.images.genericJournalImage}
                />
                <div style={
                    {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        paddingTop: "10px"
                    }
                }>
                    <div style={{ marginBottom: "20px" }}>
                        <Typography variant="h6">Journal Description</Typography>
                        <Typography variant="body2">{props.abo.description ? props.abo.description : "There is no description available for the current publication."}</Typography>
                    </div>
                    <CstPrimaryButton onClick={handleSubscriptionClick}>{props.isSubscribed ? "Unsubscribe" : "Subscribe"}</CstPrimaryButton>
                </div>

                <div style={
                    {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginLeft: "auto",
                        marginRight: "20px",
                        paddingTop: "10px"
                    }
                }>
                    <div>
                        {isPrivileged &&
                        <CstPrimaryButton style={{ marginBottom: "20px" }} maxWidth="250px" onClick={handleShowPrivilegedContentClick}>{isExpanded ? "Hide" : "Show"} admin-only functions</CstPrimaryButton>}
                        {isPrivileged && isExpanded ? (<>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent:"space-between", marginBottom: "20px" }}>
                                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Library Sub?</Typography>
                                <ToggleButton
                                    value="check"
                                    selected={isLibrarySub}
                                    onChange={handleSetIsLibrarySub}
                                    sx={{
                                        
                                        color: isLibrarySub ? "green" : "red",
                                        height: "20px",
                                        width: "20px"
                                    }}
                                >
                                   {isLibrarySub && <CheckIcon />}
                                   {!isLibrarySub && <CloseIcon/>}
                                </ToggleButton>
                            </Box>
                            <CustomizedInputBase title="Patterns" patterns={patterns} aboId={props.abo.aboId} /></>) : null}
                    </div>

                </div>


            </div>
        </>
    );
}

export default ExpandedTableRowContent;